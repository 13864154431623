@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body, #root {
    @apply h-full font-sans;
  }

  body {
    @apply bg-background text-white overflow-auto;
  }
}

@layer components {
  .page-content {
    @apply max-w-content mx-auto p-5 pt-12 text-left min-h-full;
  }
  
  .page-content h2 {
    @apply text-4xl font-bold mb-5;
  }
  
  .page-content p {
    @apply text-lg leading-relaxed;
  }
}